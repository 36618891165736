import Accordion from 'primevue/accordion'
import AccordionTab from 'primevue/accordiontab'
import Badge from 'primevue/badge'
import BlockUI from 'primevue/blockui'
import Button from 'primevue/button'
import Checkbox from 'primevue/checkbox'
import Chip from 'primevue/chip'
import Card from 'primevue/card'
import Chips from 'primevue/chips'
import ColorPicker from 'primevue/colorpicker'
import ConfirmDialog from 'primevue/confirmdialog'
import ConfirmPopup from 'primevue/confirmpopup'
import ContextMenu from 'primevue/contextmenu'
import DeferredContent from 'primevue/deferredcontent'
import Dialog from 'primevue/dialog'
import Divider from 'primevue/divider'
import Dropdown from 'primevue/dropdown'
import Fieldset from 'primevue/fieldset'
import FileUpload from 'primevue/fileupload'
import FloatLabel from 'primevue/floatlabel'
import IconField from 'primevue/iconfield'
import Image from 'primevue/image'
import InlineMessage from 'primevue/inlinemessage'
import Inplace from 'primevue/inplace'
import InputIcon from 'primevue/inputicon'
import InputMask from 'primevue/inputmask'
import InputNumber from 'primevue/inputnumber'
import InputSwitch from 'primevue/inputswitch'
import InputText from 'primevue/inputtext'
import InputGroup from 'primevue/inputgroup'
import Knob from 'primevue/knob'
import Menu from 'primevue/menu'
import Menubar from 'primevue/menubar'
import Message from 'primevue/message'
import OverlayPanel from 'primevue/overlaypanel'
import Panel from 'primevue/panel'
import ProgressBar from 'primevue/progressbar'
import RadioButton from 'primevue/radiobutton'
import SelectButton from 'primevue/selectbutton'
import ScrollPanel from 'primevue/scrollpanel'
import ScrollTop from 'primevue/scrolltop'
import Slider from 'primevue/slider'
import Sidebar from 'primevue/sidebar'
import Skeleton from 'primevue/skeleton'
import SplitButton from 'primevue/splitbutton'
import TabMenu from 'primevue/tabmenu'
import Tag from 'primevue/tag'
import TieredMenu from 'primevue/tieredmenu'
import Textarea from 'primevue/textarea'
import Toast from 'primevue/toast'
import Toolbar from 'primevue/toolbar'
import TabView from 'primevue/tabview'
import TabPanel from 'primevue/tabpanel'
import ToggleButton from 'primevue/togglebutton'
import TriStateCheckbox from 'primevue/tristatecheckbox'
import VirtualScroller from "primevue/virtualscroller";


// import 'primeicons/primeicons.css'
import PrimeVue from 'primevue/config'

// directives
import BadgeDirective from 'primevue/badgedirective'
import Ripple from 'primevue/ripple'
// import StyleClass from 'primevue/styleclass'
import Tooltip from 'primevue/tooltip'
import FocusTrap from 'primevue/focustrap'

// services
import ConfirmationService from 'primevue/confirmationservice'
import ToastService from 'primevue/toastservice'
import type {UserModule} from '@/types'

export const install: UserModule = ({ app }) => {
  // directives
  app.directive('badge', BadgeDirective)
  app.directive('ripple', Ripple)
  app.directive('tooltip', Tooltip)
  // app.directive('styleclass', StyleClass)
  app.directive('focustrap', FocusTrap)

  // components
  app.component('Accordion', Accordion)
  app.component('AccordionTab', AccordionTab)
  app.component('Badge', Badge)
  app.component('BlockUI', BlockUI)
  app.component('Button', Button)
  app.component('Checkbox', Checkbox)
  app.component('Chip', Chip)
  app.component('Card', Card)
  app.component('Chips', Chips)
  app.component('ColorPicker', ColorPicker)
  app.component('ConfirmDialog', ConfirmDialog)
  app.component('ConfirmPopup', ConfirmPopup)
  app.component('ContextMenu', ContextMenu)
  app.component('DeferredContent', DeferredContent)
  app.component('Dialog', Dialog)
  app.component('Divider', Divider)
  app.component('Dropdown', Dropdown)
  app.component('Fieldset', Fieldset)
  app.component('FileUpload', FileUpload)
  app.component('FloatLabel', FloatLabel)
  app.component('IconField', IconField)
  app.component('Image', Image)
  app.component('InlineMessage', InlineMessage)
  app.component('Inplace', Inplace)
  app.component('InputIcon', InputIcon)
  app.component('InputMask', InputMask)
  app.component('InputGroup', InputGroup)
  app.component('InputNumber', InputNumber)
  app.component('InputSwitch', InputSwitch)
  app.component('InputText', InputText)
  app.component('Knob', Knob)
  app.component('Menu', Menu)
  app.component('Menubar', Menubar)
  app.component('Message', Message)
  app.component('OverlayPanel', OverlayPanel)
  app.component('Panel', Panel)
  app.component('ProgressBar', ProgressBar)
  app.component('RadioButton', RadioButton)
  app.component('SelectButton', SelectButton)
  app.component('ScrollPanel', ScrollPanel)
  app.component('ScrollTop', ScrollTop)
  app.component('Slider', Slider)
  app.component('Sidebar', Sidebar)
  app.component('Skeleton', Skeleton)
  app.component('SplitButton', SplitButton)
  app.component('TabMenu', TabMenu)
  app.component('TabView', TabView)
  app.component('TabPanel', TabPanel)
  app.component('Tag', Tag)
  app.component('Textarea', Textarea)
  app.component('TieredMenu', TieredMenu)
  app.component('Toast', Toast)
  app.component('Toolbar', Toolbar)
  app.component('ToggleButton', ToggleButton)
  app.component('TriStateCheckbox', TriStateCheckbox)
  app.component('VirtualScroller', VirtualScroller)

  app.use(PrimeVue, { ripple: true })

  // services
  app.use(ConfirmationService)
  app.use(ToastService)
};