<script setup lang="ts">
import Review from "@/components/basic/Reviews/Review.vue";

const reviews = ref([
    {
        title: 'Excellent plugin',
        text: 'The plugin works great. There is an option to customize the speed and more Option to zoom. And most importantly, quick support for a perfect result.Thank you jeroen',
        stars: 5,
        link: 'https://wordpress.org/support/plugin/360deg-javascript-viewer/reviews/',
        author: 'dasipic',
        source: 'wordpress.org'
    },
    {
        title: 'Perfect',
        text: 'Works super good, very happy with it! 🙂 Simple, fast and reliable!',
        stars: 5,
        link: 'https://wordpress.org/support/plugin/360deg-javascript-viewer/reviews/',
        author: 'larspepe',
        source: 'wordpress.org'
    },
    {
        title: 'Fantastic Support',
        text: 'I purchased a licence for the plugin and had a few issues. The issues were more related to the non-standard WordPress template I was working on, rather than the plugin. Anyway, Jeroen, despite being on the other side of the World from me, responded to my support request within a few minutes. He then spent several hours working on a solution for my particular issue. To say I am impressed is an understatement. The plugin is fantastic, but the support is even better.',
        link: 'https://wordpress.org/support/plugin/360deg-javascript-viewer/reviews/',
        stars: 5,
        author: 'sterndale',
        source: 'wordpress.org'
    },
    ])
</script>

<template>
    <div class="grid flex align-items-stretch">
        <div v-for="review in reviews" class="col-12 sm:col-6 md:col-4 mt-3 sm:mt-0" data-aos="fade-up">
        <Review :key="review.title" :review="review"/>
        </div>
    </div>
</template>

<style scoped>

</style>