<script setup lang='ts'>
export interface TextElementInterface {
    title: string,
    text: string
    icon?: string
}

const props = withDefaults(defineProps<{
    textElement?: TextElementInterface | null,
    useH1?: boolean,
    useH2?: boolean,
    useH3?: boolean,
}>(), {
    textElement: null,
    useH1: false,
    useH2: true,
    useH3: false
});


const headerType = computed(() => {
    return props.useH1 ? 'h1' : props.useH2 ? 'h2' : props.useH3 ? 'h3' : 'h2';
});
</script>

<template>
    <div class="bg w-full pl-2 pr-2" v-if="props.textElement">
        <h1 v-if="headerType === 'h1'">{{ props.textElement.title }}</h1>
        <h2 v-if="headerType === 'h2'">{{ props.textElement.title }}</h2>
        <h3 v-if="headerType === 'h3'">{{ props.textElement.title }}</h3>
        
        <p v-html="props.textElement.text"></p>
    </div>
</template>

<style scoped lang="scss">

</style>