export const shorten = (text:string, length:number = 200) => {
        return text.length > length ? text.substring(0, length) + '...' : text;
    };

export const paymentUrl = (url?:string) => {
    if (!url) {
        return 'https://store.payproglobal.com/checkout?products[1][id]=17108&page-template=14805&products[1][agreement-id]=1345646';
    }
    return `https://store.payproglobal.com/checkout?products[1][id]=17108&page-template=14805&custom-fields[13117][]=${url}&products[1][agreement-id]=1345646`;
}

export const navigateToPaymentUrl = (url?: string) => {
   const pUrl = paymentUrl(url);
    window.open(pUrl, '_blank');
};

export const navigateToAffiliateUrl = () => {
   const pUrl = 'https://cc.payproglobal.com/AffiliateSignup/8D90998860CF078';
    window.open(pUrl, '_blank');
};