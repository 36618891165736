// register vue composition api globally
import {ViteSSG} from 'vite-ssg'
import generatedRoutes from 'virtual:generated-pages'
import {setupLayouts} from 'virtual:generated-layouts'
import App from './App.vue'
import type {UserModule} from '@/types'

const routes = setupLayouts(generatedRoutes)
let loaded = false

export const createApp = ViteSSG(
    App,
    {routes},
    (ctx) => {
        ctx.router.isReady().then(() => {
            if (loaded) return;
            loaded = true;
            Object.values(import.meta.glob<{ install: UserModule }>('./modules/*.ts', {eager: true}))
                .forEach(i => i.install?.(ctx))
        });
    },
)