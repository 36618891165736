<script setup lang="ts">
import {shorten} from "@/modules/Utilities";

const props = defineProps<{
    review: {
        title: string,
        text: string,
        author: string,
        source: string,
        stars: number,
        link: string
    }
}>()

</script>

<template>
    <div class="flex flex-column h-full">
        <div class=" mb-0">
            <h3 class="h-1rem text-center sm:text-left">{{ props.review.title }}</h3>
        </div>
        <div class="flex justify-content-center sm:justify-content-start">
            <span v-for="x in props.review.stars" class="icon-star-full"></span>
        </div>
        <div class="flex-grow-1">
            <p class="text-center sm:text-left">{{ shorten(review.text) }}</p>
        </div>
        <div class="mt-2 text-center sm:text-left">
            --{{ review.author}} at  <a :href="review.link" target="_blank">{{ review.source}}</a>
        </div>
    </div>
</template>

<style scoped>
.icon-star-full{
    color: #FFD700;
}
</style>