<script setup lang="ts">
import {IProgress, JavascriptViewer} from "@3dweb/360javascriptviewer";


const jsv = ref<JavascriptViewer>();
const showLoader = ref(true);
const captionText = ref('Checking for more images to create a 360 view');

const mainImageUrl = ref('https://360-javascriptviewer.gumlet.io/images/phone/iphone-gold-01.png?h=320');

const loadAnimation = () => {
    showLoader.value = false;
    jsv.value = new JavascriptViewer({
        mainHolderId: 'jsv-holder-viewer',
        mainImageUrl: mainImageUrl.value,
        mainImageId: 'jsv-image-viewer',
        totalFrames: 72,
        imageUrlFormat: 'iphone-gold-xx.png',
        license: '4yaodqiquhfbudoemhmviii6',
        defaultProgressBar: true,
        zoom: true,
        inertia: 12,
        speed: 90,
        autoCDNResizer: true,
        autoCDNResizerConfig: {
            useHeight: true,
            useWidth: false,
            scaleWithZoomMax: true
        },
        zoomMax: 2,
        enableChangeImageEvent: true,
        notificationConfig: {
            dragToRotate: {
                showStartToRotateDefaultNotification: true
            }
        }
    });
    
    jsv.value.events().loadImage.on((progress: IProgress) => {
        captionText.value = `Loading image ${progress.currentImage} from ${progress.totalImages} <span class="text-secondary">(${progress.percentage}%)</span>`
    });
    
    jsv.value.events().startDragging.on((ready) => {
        setTimer(10);
    });
    
    jsv.value.events().scroll.on((ready) => {
        setTimer(10);
    });
    
    jsv.value.events().pinch.on((ready) => {
        setTimer(10);
    });
    
    jsv.value.events().changeImage.on((status) => {
        currentImageSequence.value = status.currentImage.sequence;
    });
    
    
    const actionAfterLoad = () => {
        showLoader.value = false;
        if (jsv.value !== undefined) {
            return jsv.value.rotateToDegree(180, false)
                    .then(() => {
                        if (jsv.value !== undefined) {
                            jsv.value.rotateToDegree(90).then(() => {
                                setTimer(5)
                            });
                        }
                    })
        }
    };
    
    const setCaption = () => {
        captionText.value = "Now you can rotate this image and zoom with scroll or pinch"
    };
    
    jsv.value.start().then(actionAfterLoad).then(setCaption)
            .catch((err: string) => {
                console.warn(err);
            });
};

const timer = ref();
const setTimer = (seconds: number) => {
    nTimes.value = 0;
    if (jsv.value !== undefined) {
        jsv.value.setSpeed(90);
        clearTimeout(timer.value);
        loopAble.value = false;
        timer.value = setTimeout(() => {
            loopAble.value = true;
            loop();
        }, seconds * 1000);
    }
};

const currentImageSequence = ref(0);
const loopAble = ref(true);
const nTimes = ref(0);
const loop = (degrees = 5, start = true) => {
    if (jsv.value !== undefined) {
        const outsideRange = currentImageSequence.value > 32 || currentImageSequence.value < 8;
        if (loopAble.value) {
            if (start && (jsv.value.isZoomedIn() || outsideRange)) {
                jsv.value.resetZoom().then(() => {
                    if (jsv.value !== undefined) {
                        jsv.value.rotateToFrame(21).then(() => {
                            loop(degrees);
                        });
                    }
                })
            } else {
                jsv.value.setSpeed(2)
                nTimes.value++;
                if (outsideRange) {
                    degrees *= -1;
                }
                jsv.value.rotateDegrees(degrees).then(() => {
                    if (nTimes.value < 90) {
                        loop(degrees, false);
                    }
                });
            }
        }
    }
};


onMounted(() => {
    const timoutDelay = 500;
    showLoader.value = true;
    setTimeout(() => {
        loadAnimation();
    }, timoutDelay);
});

</script>

<template>
    <div>
        <div class="presentation flex justify-content-center">
            <div id="jsv-holder-viewer">
                <img id="jsv-image-viewer" alt="example image for 360 viewer" v-bind:src="mainImageUrl"/>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
@media (max-width: $sm) {
    #jsv-holder-viewer {
        width: 100%;
    }
}

@media (min-width: $md) {
    #jsv-holder-viewer {
        height: 320px;
    }
}

#jsv-holder-viewer {
    width: 310px;
    z-index: 500;
    display: flex;
    justify-content: center;
    
    &:deep(>div){
        overflow: unset !important;
    }
}

#jsv-holder-viewer div:nth-child(3) {
    overflow: unset !important;
}
</style>