<template>
        <div class="grid">
                <div v-for="feature in features" class="col-12 sm:col-6 md:col-4">
                    <Feature :key="feature.title" :feature="feature"/>
                </div>
        </div>

</template>
<script setup lang="ts">
import Feature, {FeatureInterface} from "@/components/basic/Features/Feature.vue";



const features: Ref<Array<FeatureInterface>> = ref([
    {
        title: 'Easy to use',
        list: ['From NPM or from a CDN',
                'Wordpress plugin available',
                'Online tool to configure your presentation',
                'Embed your presentation in an IFrame'],
        icon: 'icon-cool'
    },
    {
        title: 'Customize',
        list: ['Define speed, inertia',
                'Startup rotation',
                'A lot of events and methods',
                'Preloader and multilingual notification',
                'CDN support for scaling'],
        icon: 'icon-embed2'
    },
    {
        title: 'Up to date',
        text: 'On a regular base we introduce new features. Together with other developers we are always looking for better performance and integrations. Need help with your integration? contact us.',
        icon: 'icon-wrench'
    }
])
</script>