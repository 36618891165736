<script setup lang='ts'>
import MainPresentation from "@/components/showcase/MainPresentation.vue";
import Reviews from "@/components/basic/Reviews/Reviews.vue";
import Intro from "@/components/basic/Intro.vue";
import Features from "@/components/basic/Features/Features.vue";
import WhatIs from "@/components/basic/WhatIs/WhatIs.vue";
import FeaturesHow from "@/components/basic/FeaturesHow/FeaturesHow.vue";
import useSeo from "@/composables/useSeo";

useSeo({
    title: '360 product viewer, spin products with the 360 javascript viewer',
    description: 'Create 360 product presentations with the 360 javascript viewer'
});

</script>

<template>
    <SectionElement light>
        <div class="grid">
            <div class="col-12 md:col-6 text-center md:text-left">
                <h1 class="text-3em text-color">Unleash the power<br>
                    of <span class="text-primary">360</span> products<br>
                    images</h1>
                <small>More than 9 million unique <a target="_blank" href="https://www.jsdelivr.com/package/npm/@3dweb/360javascriptviewer?tab=stats">users</a> every month</small>
                <div class="grid flex justify-content-center md:justify-content-start align-items-center mt-3 ">
                    <BuyButton/>
                </div>
            </div>
            <div class="col-12 md:col-6 mt-5 md:mt-0">
                <client-only>
                    <MainPresentation/>
                </client-only>
            </div>
        </div>
    </SectionElement>
    
    <SectionElement dark>
        <Reviews/>
    </SectionElement>
    
    <SectionElement light>
        <Intro/>
    </SectionElement>
    
    <SectionElement dark>
        <Features/>
    </SectionElement>
    
    <SectionElement light>
        <CompareVersions/>
    </SectionElement>
    
    <SectionElement dark>
        <WhatIs/>
    </SectionElement>
    
    <SectionElement light>
        <FeaturesHow/>
    </SectionElement>

</template>

<style scoped></style>