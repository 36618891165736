<template>
    <div class="grid">
        <div class="col-12 text-center sm:text-left">
            <TextElement use-h2 :text-element="mainStory"/>
        </div>
        <div class="grid">
            <div v-for="item in items" class="col-12 sm:col-6 md:col-4 text-center sm:text-left">
                <TextElement use-h3 :text-element="item"/>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import TextElement, {TextElementInterface} from "@/components/basic/TextElement.vue";

const mainStory: Ref<TextElementInterface> = ref({
    title: 'Tips for starting with 360 product images on your site',
    text: 'There are complete packages on the market with turntables and special software and even with included 360 viewers. Good solutions but they also have a price and often you are locked in by a vendor. Image hosting doesn\'t have to be expensive and the integration of the viewer on your website is a one time investment. The only real continues investment is creating the images. Just like now with your product images.',
});

const items: Ref<Array<TextElementInterface>> = ref([
    {
        title: 'Hardware',
        text: 'You need a camera, lightning and a turntable to create images of good quality. There a lot of suppliers on amazon or Alibaba (starting from $15).',
    },
    {
        title: 'Image software',
        text: 'Use special software to edit the 360 product images, this software often controls the turntable and the camera. It also controls the filenames and the size of the export.',
    },
    {
        title: 'Website integration',
        text: 'Integrate the 360 product images on your site. Add intro animations for getting attention. Use a/b testing for the best result. Check usage of the viewer by firing Google Analytics events when the user interacts with the viewer.',
    }
])
</script>