import Bugsnag from '@bugsnag/js'
import BugsnagPluginVue, {BugsnagPluginVueResult} from '@bugsnag/plugin-vue'
import type {UserModule} from '@/types'

export const install: UserModule = ({ app }) => {
    Bugsnag.start({
      apiKey: import.meta.env.VITE_BUGSNAG_API_KEY as string,
      autoTrackSessions: false,
      enabledReleaseStages: ['production'],
      plugins: [new BugsnagPluginVue()],
      appVersion: import.meta.env.VITE_VERCEL_GIT_COMMIT_SHA,
    });

    const bugsnagVue = Bugsnag.getPlugin('vue') as BugsnagPluginVueResult
    app.use(bugsnagVue)
};