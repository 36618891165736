<template>
            <div class="grid">
                <div class="col-12 text-center">
                    <h2>From images to 360 rotatable products</h2>
                </div>
              <div class="col-12 sm:col-5">
                    <p> The fastest way to convert your images to 360 rotatable products.
                    </p>
                <ul>
                    <li>Easy to use</li>
                    <li>Great for large collections</li>
                    <li>Customizable</li>
                    <li>Responsive</li>
                </ul>
                </div>
               <div class="col-12 sm:col-7 flex align-items-center">
                 <img class="img-360" src="/images/images-to-360.png" alt="images to 360 model"/>
                </div>
            </div>

</template>
<script lang="ts">
export default {}
</script>
<style lang="scss" scoped>
.img-360 {
    width: 100%;
    margin: 0 auto;
}
</style>