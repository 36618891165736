<template>
    <div class="grid">
        <div class="col-12 text-center sm:text-left">
            <TextElement use-h2 :text-element="mainStory"/>
        </div>
        <div class="col-12 mb--4 sm:mb-0 mt-3 sm:mt-0 text-center sm:text-left">
            <TextElement use-h3 :text-element="secondStory"/>
        </div>
        <div class="col-12 sm:align-items-stretch grid">
            <div v-for="item in items" class=" col-12 sm:col-4 text-center sm:text-left mt-3 sm:mt-0" data-aos="fade-up">
                <TextElement use-h3 :text-element="item"/>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import TextElement, {TextElementInterface} from "@/components/basic/TextElement.vue";

const mainStory: Ref<TextElementInterface> = ref({
    title: 'Are you ready to take your online business to the next level?',
    text: 'In today\'s fast-paced digital world, captivating your customers is crucial to standing out from the competition. That\'s why we believe that incorporating 360 product images on your website is an absolute game-changer.',
});

const secondStory: Ref<TextElementInterface> = ref({
    title: 'What are 360 Product Images?',
    text: '360 product images are interactive images that allow customers to view a product from all angles. By rotating the image, customers can see the product from the front, back, sides, and top, giving them a comprehensive view of the product. This interactive experience creates a more engaging shopping experience, helping customers make more informed buying decisions.',
});

const items: Ref<Array<TextElementInterface>> = ref([
    {
        title: 'Visual Engagement',
        text: 'Say goodbye to flat, lifeless product images. With 360 product images, your customers can engage with your products in a dynamic and captivating manner. They can rotate, zoom in, and explore every detail, creating an emotional connection that drives higher engagement and longer browsing sessions.',
        icon: 'icon-cool'
    },
    {
        title: 'Stand Out',
        text: 'In a sea of competitors vying for attention, you need to find ways to differentiate your brand. By adopting 360 product images, you instantly set yourself apart from the competition. Your website will exude professionalism and innovation, leaving a lasting impression on your customers. It\'s an effective way to showcase your commitment to providing an exceptional shopping experience.',
        icon: 'icon-embed2'
    },
    {
        title: 'Boost Conversion Rates',
        text: 'By giving your customers a more interactive and realistic view of your products, you\'ll significantly increase their confidence in making a purchase. Studies have shown that websites incorporating 360 product images experience higher conversion rates compared to those with traditional static images. The ability to examine a product from all angles reduces uncertainty, leading to more informed buying decisions.',
        icon: 'icon-wrench'
    }
])
</script>