const __pages_import_0__ = () => import("/src/pages/wordpress.vue");
const __pages_import_1__ = () => import("/src/pages/terms-and-conditions.vue");
const __pages_import_2__ = () => import("/src/pages/service.vue");
const __pages_import_3__ = () => import("/src/pages/pricing.vue");
const __pages_import_4__ = () => import("/src/pages/installation.vue");
import __pages_import_5__ from "/src/pages/index.vue";
const __pages_import_6__ = () => import("/src/pages/examples.vue");
const __pages_import_7__ = () => import("/src/pages/embed.vue");
const __pages_import_8__ = () => import("/src/pages/contact.vue");
const __pages_import_9__ = () => import("/src/pages/api.vue");
const __pages_import_10__ = () => import("/src/pages/affiliate.vue");
const __pages_import_11__ = () => import("/src/pages/Error.vue");
const __pages_import_12__ = () => import("/src/pages/404.vue");
const __pages_import_13__ = () => import("/src/pages/[...all].vue");

const routes = [{"name":"wordpress","path":"/wordpress","component":__pages_import_0__,"props":true},{"name":"terms-and-conditions","path":"/terms-and-conditions","component":__pages_import_1__,"props":true},{"name":"service","path":"/service","component":__pages_import_2__,"props":true},{"name":"pricing","path":"/pricing","component":__pages_import_3__,"props":true},{"name":"installation","path":"/installation","component":__pages_import_4__,"props":true},{"name":"index","path":"/","component":__pages_import_5__,"props":true},{"name":"examples","path":"/examples","component":__pages_import_6__,"props":true},{"name":"embed","path":"/embed","component":__pages_import_7__,"props":true,"meta":{"layout":"embed"}},{"name":"contact","path":"/contact","component":__pages_import_8__,"props":true},{"name":"api","path":"/api","component":__pages_import_9__,"props":true},{"name":"affiliate","path":"/affiliate","component":__pages_import_10__,"props":true},{"name":"Error","path":"/error","component":__pages_import_11__,"props":true,"meta":{"layout":"error"}},{"name":"404","path":"/404","component":__pages_import_12__,"props":true},{"name":"all","path":"/:all(.*)*","component":__pages_import_13__,"props":true}];

export default routes;