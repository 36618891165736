<script setup lang='ts'>

useHead({
  title: 'Viewer',
  meta: [
    {
      name: 'description',
      content: 'Viewer description',
    },
  ],
})
</script>

<template>
  <div id="bar" />
  <router-view />
</template>