
import type {UserModule} from '@/types'
import VueGtag from 'vue-gtag'

export const install: UserModule = ({ app }) => {
    app.use(VueGtag, {
        pageTrackerEnabled: true,
        config: {
            id: import.meta.env.VITE_GA_ID,
            params: {
                send_page_view: true
            },
        },
    })
};